import {type LinkProps, Link as LinkRemix} from 'react-router';

import React from 'react';
import {Scramble} from './scramble';
import {cn} from '~/utils';

type StyledLinkProps = LinkProps & {
  icon?: React.ComponentType<{className: string}>;
  href?: string;
  children: string;
};

export const StyledLink = ({
  children,
  icon: Icon,
  className,
  ...props
}: StyledLinkProps) => {
  const Component = props.href ? 'a' : LinkRemix;
  return (
    <Component
      data-link
      className={cn(Icon && 'inline-flex gap-1 items-center', className)}
      prefetch="intent"
      {...props}
    >
      {Icon && <Icon className="-my-2 size-4" />}
      <Scramble>{children}</Scramble>
    </Component>
  );
};
